import React from "react";
import Div100vh from "react-div-100vh";
import { motion } from "framer-motion";
import OutsideClickHandler from "react-outside-click-handler";

import "./NewsletterPopup.scss";

function NewsletterPopup({ visible, onClose }) {
  return (
    <Div100vh
      className="newsletter-popup"
      style={{
        pointerEvents: visible ? "all" : "none",
      }}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          onClose(false);
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: visible ? 1 : 0,
            transform: visible ? "translateX(0px)" : "translateX(-400px)",
          }}
          className="newsletter-cross"
          onClick={() => onClose(false)}
        >
          <i className="gg-chevron-right"></i>
        </motion.div>
        {/* <motion.iframe
          initial={{ opacity: 0 }}
          animate={{
            opacity: visible ? 1 : 0,
            transform: visible ? "translateX(0px)" : "translateX(-400px)",
          }}
          title="mailjet-iframe"
          className="mailjet-iframe"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://app.mailjet.com/widget/iframe/3aut/Iex"
          width="100%"
        ></motion.iframe> */}
        {/* <motion.iframe
          initial={{ opacity: 0 }}
          animate={{
            opacity: visible ? 1 : 0,
            transform: visible ? "translateX(0px)" : "translateX(-400px)",
          }}
          title="mailjet-iframe"
          className="mailjet-iframe"
          data-w-token="3f330978a0d2d25819f8"
          data-w-type="pop-in"
          frameborder="0"
          scrolling="yes"
          marginheight="0"
          marginwidth="0"
          src="https://892s.mjt.lu/wgt/892s/h3o/trigger?c=a83148e9"
          width="100%"
        ></motion.iframe> */}
        <motion.iframe
          initial={{ opacity: 0 }}
          animate={{
            opacity: visible ? 1 : 0,
            transform: visible ? "translateX(0px)" : "translateX(-400px)",
          }}
          data-w-type="embedded"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://892s.mjt.lu/wgt/892s/iiu/form?c=98557600"
          width="100%"
          style={{ height: 0 }}
        ></motion.iframe>
        {/* <script
          type="text/javascript"
          src="https://app.mailjet.com/statics/js/iframeResizer.min.js"
        ></script> */}
      </OutsideClickHandler>
    </Div100vh>
  );
}

export default NewsletterPopup;
